import React from 'react'

const Card2 = ({ title, description, author }) => {
  return (
    <>
      <div className='testimonial__card'>
        <div className="testimonial_body">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.583 17.3212C3.553 16.2272 3 15.0002 3 13.0112C3 9.51124 5.457 6.37424 9.03 4.82324L9.923 6.20124C6.588 8.00524 5.936 10.3462 5.676 11.8222C6.213 11.5442 6.916 11.4472 7.605 11.5112C9.409 11.6782 10.831 13.1592 10.831 15.0002C10.831 15.9285 10.4623 16.8187 9.80587 17.4751C9.1495 18.1315 8.25926 18.5002 7.331 18.5002C6.81766 18.4958 6.31034 18.3892 5.83856 18.1868C5.36679 17.9844 4.93999 17.6902 4.583 17.3212ZM14.583 17.3212C13.553 16.2272 13 15.0002 13 13.0112C13 9.51124 15.457 6.37424 19.03 4.82324L19.923 6.20124C16.588 8.00524 15.936 10.3462 15.676 11.8222C16.213 11.5442 16.916 11.4472 17.605 11.5112C19.409 11.6782 20.831 13.1592 20.831 15.0002C20.831 15.9285 20.4623 16.8187 19.8059 17.4751C19.1495 18.1315 18.2593 18.5002 17.331 18.5002C16.8177 18.4958 16.3103 18.3892 15.8386 18.1868C15.3668 17.9844 14.94 17.6902 14.583 17.3212Z" fill="white"/>
            </svg>
          </div>
          <h6>{ title }</h6>
          <p>{ description }</p>
          <p className="author">By { author }</p>
        </div>
      </div>
    </>
  )
}

export default Card2;
