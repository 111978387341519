import React, { useEffect, useState } from 'react';
import tickIcon from '../../images/icons/MdCheck.svg';
import { HiPlusSmall } from "react-icons/hi2";
import { Link, useNavigate, useParams } from 'react-router-dom';
import creditCardImage from '../../images/icons/credit-card.svg';
import { useSelector } from 'react-redux';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { BpRadio } from '../baseComponents/Radio';
import PayItemCard from '../baseComponents/PayItemCard';
import { loadStripe } from '@stripe/stripe-js';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import { shippingFormValidationSchema } from '../../utils/yubSchema/shippingAddressValidation';
import { buyProduct, createOrder, getAddress } from '../../utils/commonapi';



const ShippingForm = () => {

  const [isApartmentField, setIsApartmentField] = useState(false);
  const [isBillingApartmentField, setIsBillingApartmentField] = useState(false);
  const [billingAddress, setBillingAddress] = useState('same');
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState([]);

  const { productId } = useParams();
  const navigate = useNavigate();

  const handleFieldOpen = () => {
    setIsApartmentField(!isApartmentField);
  }

  const handleBillingFieldOpen = () => {
    setIsBillingApartmentField(!isBillingApartmentField);
  }

  const handleChange = (event) => {
    setBillingAddress(event.target.value);
  }

  useEffect(() => {
    // Add padding to the body when component mounts
    document.body.classList.add('pb-0');

    // Clean up function to remove padding when component unmounts
    return () => {
      document.body.classList.remove('pb-0');
    };
  }, []); // Only run on mount and unmount

  useEffect(() => {
    const savedProduct = JSON.parse(sessionStorage.getItem('productItem'));
    if (savedProduct) {
      setProduct(savedProduct);
    } else {
      navigate(`/product/${productId}`)
    }
  }, [navigate, productId])

  useEffect(() => {
    try {
      const getAddressDetails = async () => {
        const { data: { data } } = await getAddress();

        setAddress(data?.addressInfo);
      }
      getAddressDetails();
    } catch (error) {
      console.log("🚀 ~ useEffect ~ error:", error)
    }
  },[])

  const selector = useSelector(state => state);
  const user = selector && selector?.auth && selector?.auth?.userInfo;


  const handleBuyProduct = async (data) => {
    const savedProduct = JSON.parse(sessionStorage.getItem('productItem'));

    const orderItems = savedProduct && savedProduct?.products && Array.isArray(savedProduct?.products) && savedProduct?.products?.map(item => {
      return {
        product: item?.id,
        name: item?.name,
        quantity: item?.quantity,
        image: item?.image,
        price: item?.price
      };
    })

    const order = { ...data, orderItems };

    try {
      const { data } = await createOrder(order);

      if (data?.success) {
        const filteredProducts = savedProduct && savedProduct?.products && Array.isArray(savedProduct?.products) && savedProduct?.products?.map(item => {
          return {
            product: item?.id,
            name: item?.name,
            image: item?.image,
            price: item?.price,
            quantity: 1,
            orderId: data?.data?._id
          }
        })

        const productItem = {
          products: filteredProducts
        }

        const { data: { sessionId } } = await buyProduct(productItem);
        // Initialize Stripe with your publishable key
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

        // Redirect to Checkout
        const { error } = await stripe.redirectToCheckout({
          sessionId,
        });

        if (error) {
          console.error('Error redirecting to checkout:', error);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleBuyProduct ~ error:", error)
    }
  }

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(
      shippingFormValidationSchema
    ),
    mode: "onSubmit",
    reValidateMode: "onBlur",
    values: {
      shipping : address
    }
  })

  const { errors } = formState;

  return (
    <>
      <section className="shippingform__section">
        <div className="shippingform_wrappper">
          <div className="form__Section">
            <form action="" onSubmit={handleSubmit(handleBuyProduct)}>
              <div className="d-flex justify-content-between align-items-center">
                <h2 className='heading'>Contact</h2>
                {
                  !user && <Link to={'/login'}>Login</Link>
                }
              </div>
              <div className="form-group mb-2">
                <input type="email" name="email" id="email" {...register('email')} placeholder='Enter the email id' className='form-control email' />
                <small className='text-danger err'>{errors?.email?.message}</small>
              </div>
              <div className="form-check d-flex p-0 m-0">
                <input className="form-check-input d-none" {...register('news_updates')} type="checkbox" id="flexCheckDefault"/>
                <label className="form-check-label mt-1" htmlFor="flexCheckDefault">
                  <div className="cus-checkbox checkbox-dark">
                    <img src={tickIcon} alt="tickIcon" />
                  </div>
                  <span className='text-dark'>Email me with news and offers</span>
                </label>
              </div>
              <h2 className="heading mt-3">Delivery</h2>
              <div className="delivery-address-field">
                <div className="form-group mb-3">
                  <input type="text" name="country" id="country" {...register('shipping.country')} placeholder='Country' className='form-control country' />
                  <small className='text-danger err'>{errors?.shipping?.country?.message}</small>
                </div>
                <div className="row m-0 mb-3">
                  <div className="col-md-6 p-0 pe-md-2 mb-3 mb-md-0">
                    <div className="form-group">
                      <input type="text" name="f_name" {...register('shipping.f_name')} id="f_name" placeholder='First name' className='form-control f_name' />
                      <small className='text-danger err'>{errors?.shipping?.f_name?.message}</small>
                    </div>
                  </div>
                  <div className="col-md-6 p-0 ps-md-2">
                    <div className="form-group">
                      <input type="text" name="l_name" {...register('shipping.l_name')} id="l_name" placeholder='Last name' className='form-control l_name' />
                      <small className='text-danger err'>{errors?.shipping?.l_name?.message}</small>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-1">
                  <input type="text" name="address" id="address" {...register('shipping.address')} placeholder='Address' className='form-control address' />
                  <small className='text-danger err'>{errors?.shipping?.address?.message}</small>
                </div>
                <span className='apartment_field_btn mb-3' onClick={handleFieldOpen}><HiPlusSmall/> Add apartment, suite, etc.</span>
                {
                  isApartmentField &&  <div className="form-group mb-3">
                    <input type="text" name="apartments" id="apartments" {...register('shipping.apartments')} placeholder='Apartment, suite, etc. (optional)' className='form-control apartments' />
                    <small className='text-danger err'></small>
                  </div>
                }
                <div className="row m-0 mb-3">
                  <div className="col-md-4 p-0 pe-md-2 mb-3 mb-md-0">
                    <div className="form-group">
                      <input type="text" name="city" id="city" {...register('shipping.city')} placeholder='City' className='form-control city' />
                      <small className='text-danger err'>{errors?.shipping?.city?.message}</small>
                    </div>
                  </div>
                  <div className="col-md-4 p-0 px-md-2 mb-3 mb-md-0">
                    <div className="form-group">
                      <input type="text" name="state" id="state" {...register('shipping.state')} placeholder='State' className='form-control state' />
                      <small className='text-danger err'>{errors?.shipping?.state?.message}</small>
                    </div>
                  </div>
                  <div className="col-md-4 p-0 ps-md-2">
                    <div className="form-group">
                      <input type="text" name="pincode" id="pincode" {...register('shipping.pincode')} placeholder='Pin code' className='form-control pincode' />
                      <small className='text-danger err'>{errors?.shipping?.pincode?.message}</small>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <input type="text" name="phone" {...register('phone')} id="phone" placeholder='Phone' className='form-control phone' />
                  <small className='text-danger err'>{errors?.phone?.message}</small>
                </div>
              </div>
              <div className="form-check d-flex p-0 m-0 mb-3">
                <input
                  className="form-check-input d-none"
                  name='isSave'
                  {...register('isSave')}
                  type="checkbox"
                  id="saveAsAddress"
                />
                <label className="form-check-label mt-1" htmlFor="saveAsAddress">
                  <div className="cus-checkbox checkbox-dark">
                    <img src={tickIcon} alt="tickIcon" />
                  </div>
                  <span className='text-dark'>Save this address information for next time</span>
                </label>
              </div>

              <h2 className="heading mb-1">Payment</h2>
              <p>All transactions are secure and encrypted.</p>
              <div className="payment-gateway-details">
                <div className="payment-header">
                  <p>Secure Payment (Cards)</p>
                </div>
                <div className="payment-body">
                  <img src={creditCardImage} alt="creditCardImage" />
                  <p>After clicking “Pay now”, you will be redirected to Secure (Cards) to complete your purchase securely.</p>
                </div>
              </div>
              <h2 className="heading mt-3">Billing address</h2>
              <div className="billing-details-wrapper">
                <RadioGroup
                  defaultValue="female"
                  aria-labelledby="demo-customized-radios"
                  name="billingAddress"
                  onChange={handleChange}
                  value={billingAddress}
                >
                  <div className="billing-checkbox">
                    <FormControlLabel value="same" {...register("billingAddress")} control={<BpRadio />} label="Same as shipping address" />
                  </div>
                  <div className="billing-checkbox">
                    <FormControlLabel value="different" {...register("billingAddress")} control={<BpRadio />} label="Use a different billing address" />
                  </div>
                </RadioGroup>
              </div>
              {
                billingAddress === "different" &&  <div className="billing-address-field mt-3">
                  <div className="form-group mb-3">
                    <input type="text" name="billing_country" {...register("billing.billing_country")} id="billing_country" placeholder='Country' className='form-control country' />
                    <small className='text-danger err'>{errors?.billing?.billing_country?.message}</small>
                  </div>
                  <div className="row m-0 mb-3">
                    <div className="col-md-6 p-0 pe-md-2 mb-3 mb-md-0">
                      <div className="form-group">
                        <input type="text" name="billing_f_name" {...register("billing.billing_f_name")} id="billing_f_name" placeholder='First name' className='form-control f_name' />
                        <small className='text-danger err'>{errors?.billing?.billing_f_name?.message}</small>
                      </div>
                    </div>
                    <div className="col-md-6 p-0 ps-md-2">
                      <div className="form-group">
                        <input type="text" name="billing_l_name" {...register("billing.billing_l_name")} id="billing_l_name" placeholder='Last name' className='form-control l_name' />
                        <small className='text-danger err'>{errors?.billing?.billing_l_name?.message}</small>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-1">
                    <input type="text" name="billing_address" {...register("billing.billing_address")} id="billing_address" placeholder='Address' className='form-control address' />
                    <small className='text-danger err'>{errors?.billing?.billing_address?.message}</small>
                  </div>
                  <span className='apartment_field_btn mb-3' onClick={handleBillingFieldOpen}><HiPlusSmall/> Add apartment, suite, etc.</span>
                  {
                    isBillingApartmentField &&  <div className="form-group mb-3">
                      <input type="text" name="billing_apartments" {...register("billing.billing_apartments")} id="billing_apartments" placeholder='Apartment, suite, etc. (optional)' className='form-control apartments' />
                      <small className='text-danger err'></small>
                    </div>
                  }
                  <div className="row m-0 mb-3">
                    <div className="col-md-4 p-0 pe-md-2 mb-3 mb-md-0">
                      <div className="form-group">
                        <input type="text" name="billing_city" {...register("billing.billing_city")} id="billing_city" placeholder='City' className='form-control city' />
                        <small className='text-danger err'>{errors?.billing?.billing_city?.message}</small>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-md-2 mb-3 mb-md-0">
                      <div className="form-group">
                        <input type="text" name="billing_state" {...register("billing.billing_state")} id="billing_state" placeholder='State' className='form-control state' />
                        <small className='text-danger err'>{errors?.billing?.billing_state?.message}</small>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 ps-md-2">
                      <div className="form-group">
                        <input type="text" name="billing_pincode" {...register("billing.billing_pincode")} id="billing_pincode" placeholder='Pin code' className='form-control pincode' />
                        <small className='text-danger err'>{errors?.billing?.billing_pincode?.message}</small>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <button className='btn btn-blue w-100 mt-4'
                disabled={loading}
              >Pay Now</button>
            </form>
          </div>
          <div className="itemDetails__section">
            <div className="total-container">
              <PayItemCard data={product} />
              <div className="total-price-wrapper">
                <div className="price-wrapper">
                  <p>Sub Total</p>
                  <p>Rs.3000</p>
                </div>
                <div className="price-wrapper">
                  <p>Shipping</p>
                  <p>Rs.30</p>
                </div>
                <div className="price-wrapper">
                  <p className='cfw-600'>Total</p>
                  <p>Rs.3030</p>
                </div>
                <span>Including ₹9.48 in taxes</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ShippingForm
