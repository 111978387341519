import React from 'react';
import { Link } from 'react-router-dom';
import searchIcon from '../../images/icons/search-icon.svg';
import bagIcon from '../../images/icons/bag-icon.svg';
import bellIcon from '../../images/icons/bell-icon.svg';
import ProfileAvatar from '../baseComponents/ProfileAvatar';
import profileImg from '../../images/avatar.jpeg';
import { IoClose } from 'react-icons/io5';
import { LuAlarmClock, LuCalendarDays, LuHelpCircle } from 'react-icons/lu';
import CartPopup from '../baseComponents/CartPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setCartOpen } from '../../reducers/cartSlice';
import { Badge } from '@mui/material';
import { BiLogOutCircle } from 'react-icons/bi';
import { BsSuitHeart } from 'react-icons/bs';
import { MdOutlineAccountBox } from 'react-icons/md';
import { Logout } from '../../utils/commonapi';
import { userLogout } from '../../reducers/auth';


const Header = ({ className }) => {
  const data = [...Array(20).keys()];

  const dispatch = useDispatch();

  const handleCartOpenEvent = (open) => {
    dispatch(setCartOpen(open))
  }

  const selector = useSelector(state => state);
  const cartsLength = selector && selector?.cart?.carts && selector?.cart?.carts?.items?.length;
  const isOpen = selector && selector?.cart?.cartIsOpen;

  const user = selector && selector?.auth && selector?.auth?.userInfo;

  const handleLogout = async () => {
    try {
      await Logout();
      dispatch(userLogout());
    } catch (error) {
      console.log("🚀 ~ handleLogout ~ error:", error)
    }
  }

  return (
    <>
      <header className={`land-header ${className}`}>
        <nav className='navbar'>
          <div className="container-fluid">
            <a href="/" className="navbar-brand">
              {/* JK Cocount Oil */}
              EOP
            </a>
            <div className="menu-items">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link active" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/"}>Products</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">About Us</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">Blogs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="menu-icons px-2 px-md-0">
              <img src={searchIcon} className='img-fluid icon' alt="searchIcon" />
              <div className="dropdown menu-dropdown-items">
                <div className="icon" data-bs-toggle="dropdown" aria-expanded="false">
                  <Badge color='success' badgeContent={1} max={999}>
                  <img src={bellIcon} className='img-fluid' alt="bellIcon" />
                  </Badge>
                </div>
                <ul className="dropdown-menu dropdown-menu-end notification-popup" onClick={(e)=>e.stopPropagation()}>
                  <li>
                    <div className="nofitication-header">
                      <h2>Notifications</h2>
                      <span>Mark all as read</span>
                    </div>
                  </li>
                  <li>
                    <div className="nofitication-body">
                      {
                        data?.map((item, key) => (
                          <div className="notification-item" key={key}>
                            <ProfileAvatar profileImage={profileImg} imgSize={40} className={'profile-icon'} />
                            <div className="notification-detail-wrapper">
                              <div className="notificaton-details">
                                <div className="title-wrapper">
                                  <h6>John</h6>
                                  <div className="close-icon">
                                    <IoClose color={"#495584"} />
                                  </div>
                                </div>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                              </div>
                              <div className="notification-timing-details">
                                <div className="time-wrapper">
                                  <LuAlarmClock color={"#000"} />
                                  <span>10:00 AM</span>
                                </div>
                                <div className="time-wrapper">
                                  <LuCalendarDays color={"#000"} />
                                  <span>Aug 10, 2023</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      {
                        data?.length === 0 &&  <div className="nodata-found">
                          <p>No data found</p>
                        </div>
                      }
                    </div>
                  </li>
                  <li>
                    <div className="notification-footer">
                      <p>Notification history</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="c-icon d-none d-md-block">
                <Badge color='success' badgeContent={cartsLength} max={999}>
                  <img src={bagIcon} className='img-fluid icon' onClick={()=>handleCartOpenEvent(true)} alt="bagIcon" />
                </Badge>
              </div>
              {
                user && <div className="dropdown menu-dropdown-items">
                  <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <ProfileAvatar profileImage={profileImg} className={'profile-image__icon'} imgSize={ 34 } />
                    <span>
                      {user?.name ? user?.name : "Anonymous"}
                    </span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <div className="dropdown-menu-item">
                        <MdOutlineAccountBox fontSize={24} color={"#285430"} />
                        <span>Profile</span>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-menu-item">
                        <BsSuitHeart fontSize={24} color={"#285430"} />
                        <span>Watchist</span>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-menu-item">
                        <div className="line"></div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-menu-item">
                        <LuHelpCircle fontSize={24} color={"#285430"} />
                        <span>Help</span>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-menu-item" onClick={handleLogout}>
                        <BiLogOutCircle fontSize={24} color={"#285430"} />
                        <span>Log out</span>
                      </div>
                    </li>
                  </ul>
                </div>
              }
              {
                !user && <Link to={'/login'} className='btn auth-btn'>Sign In</Link>
              }
            </div>
          </div>
        </nav>
      </header>
      <CartPopup popupStatus={isOpen} toggleDrawer={handleCartOpenEvent} />
    </>
  )
}

export default Header;
