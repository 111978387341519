import React, { useEffect, useState } from 'react';
import Header from '../components/groupComponents/header';
import Footer from '../components/groupComponents/footer';
import ProductInfo from '../components/groupComponents/product-info';
import BottomBuyWrapper from '../components/baseComponents/BottomBuyWrapper';
import useWindowUtils from '../utils/useWindowUtils';
import ProductDetailsPageMobHeader from '../components/baseComponents/ProductDetailsPageMobHeader';
import DialogBox from '../components/baseComponents/DialogBox';
import SwipeableEdgeDrawer from '../components/baseComponents/CustomDrawer';
import { useSelector } from 'react-redux';

const ProductDetails = () => {
  const selector = useSelector(state => state);
  const popupOpen = selector && selector?.popup && selector?.popup?.is_open;
  const data = selector && selector?.popup && selector?.popup?.data;
  const [openPopup, setOpenPopup] = useState();

  const { width } = useWindowUtils();

  useEffect(() => {
    width > 768 ? setOpenPopup("dialogbox") : setOpenPopup("bottomsheet")
  }, [width]);
  return (
    <>
      {
        width > 768 ? <Header /> : <ProductDetailsPageMobHeader />
      }
      <ProductInfo />
      {
        width > 768 ?  <Footer /> :  <BottomBuyWrapper />
      }
      {
        popupOpen && openPopup === "dialogbox" && <DialogBox status={popupOpen} data={data} />
      }
      {
        popupOpen && openPopup === "bottomsheet" && <SwipeableEdgeDrawer status={popupOpen} data={data} />
      }
    </>
  )
}

export default ProductDetails;
