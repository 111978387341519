import React from 'react';
import backIcon from '../../images/icons/back-arrow.svg';
import shareIcon from '../../images/icons/share.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPopupData, setPopupOpen } from '../../reducers/productInfoPopup';

const ProductDetailsPageMobHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous route
  };

  const shareData = {
    component: "ProductShareCard",
    data: {
      url : window.location.href
    }
  }

  const handleShareLink = () => {
    dispatch(setPopupOpen(true));
    dispatch(setPopupData(shareData));
  }

  return (
    <>
      <div className="details-page-header">
        <div className="back-icon" onClick={handleGoBack}>
          <img src={backIcon} className='img-fluid' alt="back" />
        </div>
        <Link to="/" className="navbar-brand">
              {/* JK Cocount Oil */}
              EOP
        </Link>
        <div className="share-icon" onClick={handleShareLink}>
          <img src={shareIcon} className='img-fluid' alt="back" />
        </div>
      </div>
    </>
  )
}

export default ProductDetailsPageMobHeader;
