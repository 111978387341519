import React from 'react';

const ImageComponent = (
  {
    url,
    altText,
    className
  }) => {
  return (
    <>
      <img src={url} className={className} alt={altText} />
    </>
  )
}

export default ImageComponent;
