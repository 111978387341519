import React, { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';



const StarRating = ({ outlineColor = '#000000', mode, rating = 0, fillColor="#FDC643" }) => {
  const [value, setValue] = useState(rating);
  useEffect(() => {
    setValue(rating)
  }, [rating])
  return (
    <>
      {
        mode === "readOnly" ? <Rating name="half-rating-read" precision={0.5} value={value} readOnly sx={{
          '& .MuiRating-icon': {
            color: outlineColor,
          },
          '& .MuiRating-iconFilled': {
            color: fillColor,
          },
        }}/> :
        <Rating
          name="half-rating"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            '& .MuiRating-icon': {
              color: outlineColor,
            },
            '& .MuiRating-iconFilled': {
              color: fillColor,
            },
          }}
        />
      }
    </>

  )
}

export default StarRating;
