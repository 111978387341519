import React from 'react';
import styled from '@emotion/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
  },
}));

const CustomTooltip = ({ children, title, type, placement = "top" }) => {
  return (
    <>
      {
        type === 'bootstrap' ?
        <BootstrapTooltip title={title} placement={placement}>
          {children}
        </BootstrapTooltip> :
        <Tooltip title={title} placement={placement}>
          {children}
        </Tooltip>
      }
    </>
  )
}

export default CustomTooltip;
