import * as Yup from 'yup';

const reviewValidationSchema = Yup.object().shape({
  rating: Yup.number()
  .typeError('Rating must be a number')
  .required('Rating is required').min(1).max(5, 'Enter rating less or equal to 5'),
  comment: Yup.string().required('Comment is required')
});

export default reviewValidationSchema;
