import React from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { setClosePopup } from '../../reducers/productInfoPopup';
import { useDispatch } from 'react-redux';

const AskQuestionPopup = ({ details }) => {
  const dispatch = useDispatch();

  const { data } = details;
  const handleClickClose = () => {
    dispatch(setClosePopup(false));
  }


  return (
    <>
      <div className="ask-question-popup">
        <div className="card-header">
          <label className='title'>Ask a Question</label>
          <div className="close_icon" onClick={handleClickClose}>
            <IoIosCloseCircleOutline />
          </div>
        </div>
        <div className="ask-question-popup-content_wrapper">
          <form action="" method="post">
            <div className="row m-0 mb-3">
              <div className="col-lg-6 p-0 pe-lg-2">
                <div className="form-dropup">
                  <input type="text" className='name form-control text-green' name='name' id='name' placeholder='Your Name'/>
                </div>
              </div>
              <div className="col-lg-6 p-0 ps-lg-2 mt-3 mt-lg-0">
                <div className="form-dropup">
                  <input type="email" className='email form-control text-green' name='email' id='email' placeholder='Your Email'/>
                </div>
              </div>
            </div>
            <div className="form-dropup mb-3">
              <input type="text" className='phone form-control text-green' name='phone' id='phone' placeholder='Your Phone'/>
            </div>
            <div className="form-dropup mb-3">
              <textarea name="message" id="message" className='form-control text-green message' cols="30" rows="4" placeholder='Your Message'></textarea>
            </div>
            <button type="submit" className='btn btn-green w-100 rounded-0'>Send Message</button>
          </form>

          <div className="card">
            <div className="card-image">
              <img src={data?.images?.[0]?.image} alt="product_img" />
            </div>
            <div className="content">
              <h6>{data?.name}</h6>
              <p>Rs.{data?.salePrice}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AskQuestionPopup;
