import React from 'react';
import mapIcon from '../../images/icons/map.svg';
import phoneIcon from '../../images/icons/phone.svg';
import emailIcon from '../../images/icons/email.svg';
import playStoreImage from '../../images/image-2.png';
import appleStoreImage from '../../images/image-1.png';
import paymentImage from '../../images/payments.png';
import Card3 from '../baseComponents/Card3';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className='d-none d-md-block footer'>
        <div className="container-fluid">
          <div className="our_services">
            <Card3 />
            <Card3 />
            <Card3 />
            <Card3 />
          </div>
          <div className="footer-container">
            <div className="menus__list">
              <div className="footer-menu__title">
                <h2>EOP</h2>
                <p>Pellentesque posuere orci lobortis</p>
              </div>
              <div className="menu-item-wrapper">
                <ul>
                  <li>
                    <img src={mapIcon} alt="mapIcon" />
                    Chennai, India
                  </li>
                  <li>
                    <img src={phoneIcon} alt="mapIcon" />
                    <a href="tel:+1234567890">1234567890</a>
                  </li>
                  <li>
                    <img src={emailIcon} alt="mapIcon" />
                    <a href="mailto:example@gmail.com">example@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menus__list">
              <div className="footer-menu__title">
                <h4>Company</h4>
              </div>
              <div className="menu-item-wrapper">
                <ul>
                  <li>About Us</li>
                  <li>Delivery Information</li>
                  <li>Privacy policy</li>
                  <li>Terms & Conditions</li>
                  <li>Contact Us</li>
                  <li>Support Center</li>
                </ul>
              </div>
            </div>
            <div className="menus__list">
              <div className="footer-menu__title">
                <h4>Account</h4>
              </div>
              <div className="menu-item-wrapper">
                <ul>
                  <li>Sign In</li>
                  <li>View Cart</li>
                  <li>My wishlist</li>
                  <li>Track my order</li>
                  <li>Help Ticket</li>
                </ul>
              </div>
            </div>
            <div className="menus__list d-none d-xl-flex">
              <div className="footer-menu__title">
                <h4>Information</h4>
              </div>
              <div className="menu-item-wrapper">
                <ul>
                  <li>Cocount Oil</li>
                  <li>Cocount Oil</li>
                  <li>Cocount Oil</li>
                  <li>Cocount Oil</li>
                  <li>Cocount Oil</li>
                </ul>
              </div>
            </div>
            <div className="menus__list">
              <div className="footer-menu__title">
                <h4>App & Payment</h4>
                <p>From App Store or Google Play</p>
              </div>
              <div className="menu-item-wrapper">
                <div className="app-links">
                  <img src={ playStoreImage } className='img-fluid' alt="playStoreImage" />
                  <img src={ appleStoreImage } className='img-fluid' alt="playStoreImage" />
                </div>
                <p>Secured Payment Gateways</p>
                <div className="payments">
                  <img src={paymentImage} className='img-fluid' alt="paymentImage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className='mobile-footer d-md-none'>
        <div className="container-fluid">
          <div className="menu-wrapper">
            <NavLink to={'/'} className={`menu-item ${({isActive}) => (isActive ? "active" : 'none')}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20 19V10.5C20 10.3448 19.9639 10.1916 19.8944 10.0528C19.825 9.91393 19.7242 9.79315 19.6 9.7L12.6 4.45C12.4269 4.32018 12.2164 4.25 12 4.25C11.7836 4.25 11.5731 4.32018 11.4 4.45L4.4 9.7C4.2758 9.79315 4.175 9.91393 4.10557 10.0528C4.03614 10.1916 4 10.3448 4 10.5V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H9C9.26522 20 9.51957 19.8946 9.70711 19.7071C9.89464 19.5196 10 19.2652 10 19V16C10 15.7348 10.1054 15.4804 10.2929 15.2929C10.4804 15.1054 10.7348 15 11 15H13C13.2652 15 13.5196 15.1054 13.7071 15.2929C13.8946 15.4804 14 15.7348 14 16V19C14 19.2652 14.1054 19.5196 14.2929 19.7071C14.4804 19.8946 14.7348 20 15 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19Z" stroke="#285430" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <p>Home</p>
            </NavLink>
            <NavLink to={'/cart'} className={`menu-item ${({isActive}) => (isActive ? "active" : 'none')}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M20.7235 12.526C20.1375 9.405 19.8455 7.844 18.7335 6.922C17.6245 6 16.0365 6 12.8615 6H12.1395C8.96447 6 7.37647 6 6.26547 6.922C5.15547 7.844 4.86247 9.405 4.27647 12.526C3.45447 16.915 3.04247 19.109 4.24247 20.555C5.44147 22 7.67347 22 12.1385 22H12.8605C17.3255 22 19.5585 22 20.7575 20.555C21.4535 19.715 21.6075 18.625 21.4535 17M9.49947 6V5C9.49947 4.20435 9.81554 3.44129 10.3781 2.87868C10.9408 2.31607 11.7038 2 12.4995 2C13.2951 2 14.0582 2.31607 14.6208 2.87868C15.1834 3.44129 15.4995 4.20435 15.4995 5V6" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round"/>
              </svg>
              <p>Cart</p>
            </NavLink>
            <div className="menu-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M3 3C3.83 3.305 4.968 3.542 5.48 4.323C5.836 4.868 5.836 5.591 5.836 7.038V9.76C5.836 12.702 5.897 13.672 6.728 14.586C7.558 15.5 8.896 15.5 11.57 15.5H16.655C19.321 15.5 19.899 14.899 20.411 12.307C20.635 11.177 20.861 10.061 20.975 8.934C21.191 6.8 20.002 6.12 18.109 6.12H5.836M16.5 21C16.8978 21 17.2794 20.842 17.5607 20.5607C17.842 20.2794 18 19.8978 18 19.5C18 19.1022 17.842 18.7206 17.5607 18.4393C17.2794 18.158 16.8978 18 16.5 18C16.1022 18 15.7206 18.158 15.4393 18.4393C15.158 18.7206 15 19.1022 15 19.5C15 19.8978 15.158 20.2794 15.4393 20.5607C15.7206 20.842 16.1022 21 16.5 21ZM8.5 21C8.89782 21 9.27936 20.842 9.56066 20.5607C9.84196 20.2794 10 19.8978 10 19.5C10 19.1022 9.84196 18.7206 9.56066 18.4393C9.27936 18.158 8.89782 18 8.5 18C8.10218 18 7.72064 18.158 7.43934 18.4393C7.15804 18.7206 7 19.1022 7 19.5C7 19.8978 7.15804 20.2794 7.43934 20.5607C7.72064 20.842 8.10218 21 8.5 21Z" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <p>Orders</p>
            </div>
            <div className="menu-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M6 8H10" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M22 10.5C22 10.423 22 9.967 21.998 9.935C21.962 9.434 21.533 9.035 20.993 9.002C20.959 9 20.918 9 20.834 9H18.232C16.446 9 15 10.343 15 12C15 13.657 16.447 15 18.23 15H20.833C20.917 15 20.958 15 20.993 14.998C21.533 14.965 21.963 14.566 21.998 14.065C22 14.033 22 13.577 22 13.5" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13Z" fill="black"/>
                <path d="M13 4C16.771 4 18.657 4 19.828 5.172C20.637 5.98 20.888 7.128 20.965 9M10 20H13C16.771 20 18.657 20 19.828 18.828C20.637 18.02 20.888 16.872 20.965 15M9 4C5.886 4.01 4.235 4.108 3.172 5.172C2 6.343 2 8.229 2 12C2 15.771 2 17.657 3.172 18.828C3.825 19.482 4.7 19.771 6 19.898" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round"/>
              </svg>
              <p>Wallet</p>
            </div>
            <div className="menu-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M12.5 10C14.7091 10 16.5 8.20914 16.5 6C16.5 3.79086 14.7091 2 12.5 2C10.2909 2 8.5 3.79086 8.5 6C8.5 8.20914 10.2909 10 12.5 10Z" stroke="black" strokeOpacity="0.5" strokeWidth="1.5"/>
                <path d="M20.498 18C20.5 17.836 20.5 17.669 20.5 17.5C20.5 15.015 16.918 13 12.5 13C8.082 13 4.5 15.015 4.5 17.5C4.5 19.985 4.5 22 12.5 22C14.731 22 16.34 21.843 17.5 21.563" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round"/>
              </svg>
              <p>Profile</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
