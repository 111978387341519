import React from 'react';
import productImage from '../../images/home/product-18.webp';
import starImage from '../../images/icons/star.svg';
// import cartImage from '../../images/icons/cart.svg';

const Card1 = ({title,price}) => {
  return (
    <>
      <div className="bestSells_card">
        <div className="card-image">
          <img src={productImage} alt="product" />
        </div>
        <div className="card-content">
          <h1>{ title }</h1>
          <p className="price">$ {price}</p>

          <div className="solid_and_rating">
            <div className="rating">
              <span>4.4</span>
              <img src={starImage} alt="starImage" />
            </div>
            <div className="line">
            </div>
            <p>( 44 Reviews )</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card1;
