import React from 'react'
import starImage from '../../images/icons/star.svg';
import moment from 'moment';

const Card5 = ({ data }) => {
  return (
    <>
      <div className="review-card">
        <div className="review-content">
          <div className="title-wrapper">
            <div className="rating">
              <span>{data?.rating}</span>
              <img src={starImage} alt="starImage" />
            </div>
            {
              data?.title && <h6 className='review-title'>{data?.title}</h6>
            }
          </div>
          <p className="description">
            {data?.comment}
          </p>
          <div className="author-details ms-auto">
            - <span>{data?.user?.name ? data?.user?.name : "Anonymous"}</span>
            <span className='date'>{data?.createdAt ? moment(data?.createdAt).format("MMM Do YY") : "6 Jun 2024"}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card5;

