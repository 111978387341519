import React from 'react';
import bannerImage from '../../images/home/banner.webp';

const Banner = () => {
  return (
    <>
      <section className='banner__section section_padding'>
        <div className="container-fluid">
          <div className="banner-wrapper">
            <div className="background__overlay">
              <img src={ bannerImage } className='img-fluid' alt="bannerImage" />
            </div>
            <div className="banner__content">
              <h1>Don’t miss amazing grocery deals</h1>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, perferendis quam sed quibusdam aliquam, architecto dolorum recusandae pariatur rem quis temporibus iure excepturi sit odio. Repellendus tempore tenetur quos a.</p>
              <button className='btn btn-white cfw-600'>Shop Now</button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner;
