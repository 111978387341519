import React, { useEffect } from 'react';

const BottomBuyWrapper = () => {

  useEffect(() => {
    // Add padding to the body when component mounts
    document.body.classList.add('product-page-pb-5');

    // Clean up function to remove padding when component unmounts
    return () => {
      document.body.classList.remove('product-page-pb-5');
    };
  }, []); // Only run on mount and unmount

  return (
    <>
      <div className='bottom-buy-wrapper'>
        <div className="price-wrapper">
          <p>Rs.250</p>
          <button className='btn btn-sm btn-green'>Add to cart</button>
        </div>
        <button className='btn btn-green w-100'>Buy Now</button>
      </div>
    </>
  )
}

export default BottomBuyWrapper;
