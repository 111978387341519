import React from 'react';
import { HiMiniMinus, HiMiniPlus } from "react-icons/hi2";
import { BsTrash } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCartOpen } from '../../reducers/cartSlice';

const Card4 = ({ data, className, handleQuantityClick, removeClickFunc }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (id) => {
    navigate(`/product/${id}`)
    dispatch(setCartOpen(false));
  }

  return (
    <>
      <div className={`card4 cursor-pointer ${className}`} onClick={()=>handleClick(data?.productId?._id)}>
        <div className="card-image">
          <img src={data?.productId?.images?.[0]?.image} alt="cardImage" />
        </div>
        <div className="card-content">
          <div className="product-details">
            <h2>{data?.productId?.title}</h2>
            <p>{data?.productId?.description}</p>
          </div>
          <div className="price">
            <p>Rs.{data?.productId?.salePrice}</p>
            {data?.productId?.salePrice !== data?.productId?.price && <p className='offer-price'>Rs.{data?.productId?.price}</p>}
          </div>
          <div className="quatity-container">
            <div className="quatity-wrapper">
              <div className="box" onClick={(e)=>handleQuantityClick(e,'dec',data?.productId?._id)}>
                <HiMiniMinus fontSize={16} color='#000' />
              </div>
              <p className="text">{data?.quantity}</p>
              <div className="box" onClick={(e)=>handleQuantityClick(e,'inc',data?.productId?._id)}>
                <HiMiniPlus fontSize={16} color='#000' />
              </div>
            </div>
            <p onClick={(e)=>removeClickFunc(e,data?.productId?._id)}><BsTrash fontSize={16} color='#4F4F4F'/> <span className='d-none d-sm-block'>Remove</span></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card4;
