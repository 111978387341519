import { appAxios } from './axios-base-utils';

export const createReviewUrl = (data) => appAxios.post('/api/v1/review', data);
export const getReviewById = (productId) => appAxios.get(`/api/v1/review/${productId}`);
export const userLoginApi = (data) => appAxios.post('/api/v1/login', data);
export const userRegisterApi = (data) => appAxios.post('/api/v1/register', data);
export const userMe = () => appAxios.get('/api/v1/user/me');
export const addCart = (data) => appAxios.post('/api/v1/cart/add',data);
export const updateCart = (data) => appAxios.post('/api/v1/cart/update',data);
export const getCarts = () => appAxios.get('/api/v1/cart');
export const removeCart = (data) => appAxios.put('/api/v1/cart/remove', data);
export const createOrder = (data) => appAxios.post('/api/v1/order/new', data);
export const buyProduct = (data) => appAxios.post('/api/v1/create-checkout-session', data);
export const saveAddress = (data) => appAxios.post('/api/v1/address/save', data);
export const getAddress = () => appAxios.get('/api/v1/address');
export const Logout = () => appAxios.get('/api/v1/logout');
