import React from 'react';
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../../utils/common';
const ProfileAvatar = ({ profileImage, ProfileName = "Demo", className='icon',imgSize }) => {
  const imageSize = { width: imgSize, height: imgSize }
  return (
    <div className={`${className}`}>
      {
        profileImage ? <Avatar alt="Remy Sharp" src={profileImage} sx={imageSize}/> : <Avatar {...stringAvatar(ProfileName)} />
      }
    </div>
  )
}

export default ProfileAvatar;
