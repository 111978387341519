import React from 'react'
import Header from '../components/groupComponents/header';
import Footer from '../components/groupComponents/footer';
import CartItems from '../components/groupComponents/cartItems';

const CartPage = () => {
  return (
    <>
      <Header />
      <CartItems />
      <Footer />
    </>
  )
}

export default CartPage;
