import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  is_open: false,
  loading:false,
  data:[]
}

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setPopupOpen: (state, { payload }) => {
      state.is_open = payload;
      state.loading = true;
    },
    setPopupData: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    setClosePopup: (state, { payload }) => {
      state.is_open = payload;
      state.data = [];
      state.loading = false;
    }
  }
})
const { reducer, actions } = popupSlice;

export const {
  setPopupOpen,
  setPopupData,
  setClosePopup
} = actions;

export default reducer;
