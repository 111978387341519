import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';

const AccordionUsage = ({ id, title, description }) => {
  const MuiAccordion = styled((props) => (
    <Accordion
      // slotProps={{ transition: { unmountOnExit: true } }}
      {...props} />
  ))(() => ({
    '&:last-of-type': {
      borderRadius:'8px',
    },
    border: `1px solid #0000004d !important`,
    boxShadow: 'none',
    borderRadius:'8px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const MuiAccordionSummary = styled((props) => (
    <AccordionSummary {...props} />
  ))(() => ({
    '&.Mui-expanded': {
      minHeight: 'unset',
      margin: 0
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  }))
  return (
    <>
      <MuiAccordion>
        <MuiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${id}`}
          id={`panel-${id}`}
        >
          {title}
        </MuiAccordionSummary>
        <AccordionDetails>
          {description}
        </AccordionDetails>
      </MuiAccordion>
    </>
  );
}

export default AccordionUsage;
