import { Drawer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CgClose } from "react-icons/cg";
import { IoMdCut } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import styled from '@emotion/styled';
import Card4 from './Card4';
import { useDispatch, useSelector } from 'react-redux';
import { getCarts, removeCart, updateCart } from '../../utils/commonapi';
import { cartRequest, setCartInfo } from '../../reducers/cartSlice';
import noCartImage from '../../images/empty-cart.svg';

const CustumDrawer = styled(Drawer)({
  ".MuiDrawer-paper": {
    overflow: "hidden",
  }
});

const CartPopup = ({ popupStatus, toggleDrawer }) => {
  const dispatch = useDispatch();

  const [cartItems, setCartItems] = useState();
  const selector = useSelector(state => state);
  const data = selector && selector?.cart?.carts;

  useEffect(() => {
    setCartItems(data);
  }, [data]);

  const handleQuantityFunc = async (event, method, productId) => {
    event.stopPropagation();
    const matchedItem = cartItems && cartItems.items && Array.isArray(cartItems.items) && cartItems?.items.find(item => item?.productId?._id === productId);
    const quantity = matchedItem?.quantity;
    const availableQuantity = matchedItem?.productId?.quantity;
    let updatedQuantity = method === "dec" ? quantity - 1 : quantity + 1;

    if (updatedQuantity === 0) {
      updatedQuantity = 1;
    } else if(updatedQuantity >= availableQuantity){
      updatedQuantity = availableQuantity;
    }

    const updateCartData = {
      productId: productId,
      quantity: updatedQuantity
    }

    try {
      await updateCart(updateCartData);
      dispatch(cartRequest());
      const { data: { data } } = await getCarts();
      dispatch(setCartInfo(data));
    } catch (error) {
      // console.log("🚀 ~ handleAddToCart ~ error:", error)
    }
  }

  const hanldeRemoveCart = async (event, productId) => {
    event.stopPropagation();
    try {
      const removeData = {
        productId
      }
      await removeCart(removeData);
      dispatch(cartRequest());
      const { data: { data } } = await getCarts();
      dispatch(setCartInfo(data));
    } catch (error) {
      console.log("🚀 ~ hanldeRemoveCart ~ error:", error)
    }
  }

  return (
    <>
      <CustumDrawer
        anchor={"right"}
        open={popupStatus}
        onClose={()=>toggleDrawer(false)}
      >
        <div className='cart-wrapper'>
          <div className="cart-header">
            <h6>Shopping Cart</h6>
            <CgClose color='#000' fontSize={24} onClick={()=>toggleDrawer(false)}/>
          </div>
          <div className="cart-body">
            {
              cartItems?.items?.length > 0 &&
              <>
                {
                  cartItems && cartItems?.items && Array.isArray(cartItems?.items) && cartItems?.items?.map((item, idx) => (
                    <Card4 data={item} key={idx} handleQuantityClick={handleQuantityFunc} removeClickFunc={hanldeRemoveCart}/>
                  ))
                }
                <div className="coupen-code__wrapper">
                  <p>Have a coupon code ? enter here</p>
                  <div className="coupen-code-input__wrapper">
                    <div className="cutter-icon">
                      <IoMdCut fontSize={16} color='#000'/>
                    </div>
                    <input type="text" name="coupen" id="coupen" />
                    <div className="right-arrow">
                      <IoIosArrowForward fontSize={16} color='#000'/>
                    </div>
                  </div>
                </div>
                <div className="details-wrapper">
                  <div className="details">
                    <p>Price:</p>
                    <p className="price">Rs.1200</p>
                  </div>
                  <div className="details">
                    <p>Tax:</p>
                    <p className="price">Rs.120</p>
                  </div>
                  <div className="details">
                    <p>Delivery Fee:</p>
                    <p className="price">Rs.50</p>
                  </div>
                  <div className="line"></div>
                  <div className="details">
                    <p>Total:</p>
                    <p className="price">Rs.1370</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="price-wrapper">
                    <p>Rs.1370</p>
                    <span>View Price Details</span>
                  </div>
                  <button className='btn btn-green'>Check Out</button>
                </div>
              </>
            }
            {
              !cartItems && <div className="nocart-wrapper">
                <img src={noCartImage} className='img-fluid' alt="noCartImage" />
                <p>Your shopping cart is empty!</p>
              </div>
            }
          </div>

        </div>
      </CustumDrawer>
    </>
  )
}

export default CartPopup;
