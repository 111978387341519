import React from 'react';

const SectionTitle = ({ title, children }) => {
  return (
    <>
      <div className="section__title">
        <h2>{title}</h2>
        {children}
      </div>
    </>
  )
}

export default SectionTitle;
