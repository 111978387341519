import React from 'react';
import { BiSolidOffer } from "react-icons/bi";

const Card3 = () => {
  return (
    <>
      <div className="card3">
        <div className="card-icon">
          <BiSolidOffer fontSize={48} color='#285430'/>
        </div>
        <div className="card-content">
          <h1>Best prices and offers</h1>
          <p>Order Rs.500 and more</p>
        </div>
      </div>
    </>
  )
}

export default Card3;
