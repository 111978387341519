import React, { useEffect, useState } from 'react'
import SectionTitle from '../baseComponents/section-title';
import { Autoplay, EffectFade } from 'swiper/modules';
import SwiperComponent from '../baseComponents/swiper';
import Card2 from '../baseComponents/Card2';
import { testimonial } from '../../utils/common';

const Testimonial = () => {
  const [data, setData] = useState(null);
  useEffect(() => {

    const fetchData = async () => {
      try {
        const {data:{data}} = await testimonial();
        setData(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setLoading(false);
      }
    }

    fetchData();
  },[])

  const swiperParams = {
    slidesPerView: 1,
    spaceBetween:16,
    autoplay:{
      delay: 3000,
    },
    speed:500,
    modules:[Autoplay, EffectFade],
    className:"testimonialSwiper",
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 1.5,
      },
      1024: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 2.5,
        spaceBetween: 32
      },
      1300: {
        slidesPerView: 3,
        spaceBetween: 32
      }
    }
  }
  return (
    <>
      <section className='testimonial-section section_padding'>
        <div className="container-fluid">
          <SectionTitle title={"Our Testimonial"} />
          <div className="testinomial___container mt-3 mt-md-4">
            <SwiperComponent
              cardData={data}
              cardComponent={<Card2 />}
              swiperParams={swiperParams}>
            </SwiperComponent>
          </div>
        </div>
      </section>
    </>
  )
}

export default Testimonial;
