import React from 'react'
import Header from '../groupComponents/header'
import Footer from '../groupComponents/footer'
import Lottie from "lottie-react";
import succcessLottie from '../../images/sucess-lottie.json';
const Success = () => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="success-card">
          <h1>Congratulations! Your Order Was Successful!</h1>
          <div className="success-icon">
            <Lottie animationData={succcessLottie} loop={true} />
          </div>
          <div className="content">
            <h2>Thank you for choosing EOP!</h2>
            <p>We're thrilled to inform you that your order has been successfully processed. You're one step closer to enjoying your purchase. We appreciate your trust in us and we're committed to providing you with exceptional service and products.</p>
            <button className='btn btn-green'>Continue Shopping</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Success
