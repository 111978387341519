import { configureStore } from '@reduxjs/toolkit'
import cartReducer from './reducers/cartSlice';
import popupReducer from './reducers/productInfoPopup';
import authReducer from './reducers/auth';

const store = configureStore({
  reducer: {
    cart : cartReducer,
    popup : popupReducer,
    auth : authReducer
  },
})

export default store;
