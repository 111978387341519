import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ProductShareCard from './ProductShareCard';
import ShippingInfoCard from './ShippingInfoCard';
import AskQuestionPopup from './AskQuestionPopup';
import { setClosePopup } from '../../reducers/productInfoPopup';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const drawerBleeding = 0;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  zIndex: 1
}));

const SwipeableEdgeDrawer = ({ status, data,child }) => {
  const dispatch = useDispatch();

  const RenderElement = useMemo(() => {
    return ({ data }) => {
      const { component } = data;
      switch (component) {
        case "ProductShareCard":
          return <ProductShareCard data={data}/>;
        case "ShippingInfoCard":
          return <ShippingInfoCard data={data}/>;
        case "AskQuestionPopup":
          return <AskQuestionPopup details={data}/>;
        default:
          return null;
      }
    };
  }, []);

  const handleClickClose = (status) => {
    dispatch(setClosePopup(status));
  }

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'auto',
            maxHeight: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'auto',
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={status}
        onClose={()=>handleClickClose(false)}
        onOpen={()=>handleClickClose(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Puller />
        <RenderElement data={data} />
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
