import React from 'react';
import Header from "../components/groupComponents/header";
import Banner from '../components/groupComponents/banner';
import MostPopularProduct from '../components/groupComponents/mostPopularProduct';
import DailyBestSells from '../components/groupComponents/daily-best-sells';
import DealsOfTheDay from '../components/groupComponents/deals-of-the-day';
import Testimonial from '../components/groupComponents/testimonial';
import Footer from '../components/groupComponents/footer';
// import AboutUs from '../components/groupComponents/aboutUs';
// import CardViewCategory from '../components/groupComponents/card-view-category';

const home = () => {
  return (
    <>
      <Header />
      <Banner />
      {/* <CardViewCategory /> */}
      <MostPopularProduct />
      {/* <AboutUs /> */}
      <DailyBestSells />
      <DealsOfTheDay />
      <Testimonial />
      <Footer />
    </>
  )
}

export default home
