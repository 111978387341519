import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import styled from '@emotion/styled';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import reviewValidationSchema from '../../utils/yubSchema/addReviewSchemaValidation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createReviewUrl } from '../../utils/commonapi';
import { useParams } from 'react-router-dom';
import CustomizedSnackbars from './Snackbar';


const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: "0px"
  }
}));

const AddReview = ({ status, handleClickClose, reviewStatus, reviewStatusSet }) => {
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(
      reviewValidationSchema
    ),
    mode: "onSubmit",
    reValidateMode: "onBlur"
  })

  const { errors } = formState;

  const handleApiError = (error) => {
    let errorMessage = 'An error occurred';
    if (error?.response) {
      errorMessage = error?.response?.data?.message;
    } else if (error?.request) {
      errorMessage = 'No response received from server';
    }
    setSnackbarMessage(errorMessage);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (isOpen) => {
    setOpenSnackbar(isOpen);
    setSnackbarMessage('');
  }

  const reviewHandleSubmit = async (data) => {
    const formData = {
      ...data,
      productId: id
    };

    try {
      const review = await createReviewUrl(formData);
      console.log("🚀 ~ reviewHandleSubmit ~ review:", review);
      reset();
      handleClickClose(false);
      reviewStatusSet(!reviewStatus);
    } catch (error) {
      console.log("🚀 ~ reviewHandleSubmit ~ error:", error)
      handleApiError(error);
    }
  };

  return (
    <>
      <BootstrapDialog
        open={status}
        keepMounted
        scroll={"paper"}
        onClose={() => handleClickClose(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent scroll={'paper'}>
          <div className="add-review__form">
            <div className="card-header">
              <label className='title'>Review</label>
              <div className="close_icon d-block" onClick={() => handleClickClose(false)}>
                <IoIosCloseCircleOutline />
              </div>
            </div>
            <div className="add-review__form-content_wrapper">
              <form action="" method="post" onSubmit={handleSubmit(reviewHandleSubmit)}>

                <div className="form-dropup mb-3">
                  <input type="text" className='title form-control text-green' {...register('title')} name='title' id='title' placeholder='Your Title (optional)' />
                </div>
                <div className="form-dropup mb-3">
                  <input type="text" className='rating form-control text-green' {...register('rating')} name='rating' id='rating' placeholder='Your Rating' />
                  <small className='text-danger err'>{errors?.rating?.message}</small>
                </div>
                <div className="form-dropup mb-3">
                  <textarea name="comment" id="comment" className='form-control text-green comment' {...register('comment')} cols="30" rows="4" placeholder='Your Comment'></textarea>
                  <small className='text-danger err'>{errors?.comment?.message}</small>
                </div>
                <button type="submit" className='btn btn-green w-100 rounded-0'>Send Message</button>
              </form>
            </div>
          </div>
          {openSnackbar && <CustomizedSnackbars open={openSnackbar} message={snackbarMessage} severity="error" handleClose={handleCloseSnackbar} />}
        </DialogContent>
      </BootstrapDialog>
    </>
  )
}

export default AddReview;
