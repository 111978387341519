import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const CustomizedSnackbars = ({ open, message, handleClose, severity, customStyles, variant, vertical = 'top', horizontal = 'right' }) => {

  return (
    <div>
      <Snackbar  anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={()=>handleClose(false)}>
        <Alert
          onClose={()=>handleClose(false)}
          severity={severity}
          variant={variant}
          sx={{ ...customStyles, width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CustomizedSnackbars;
