import React from 'react';
import Dialog from '@mui/material/Dialog';
import ProductShareCard from './ProductShareCard';
import ShippingInfoCard from './ShippingInfoCard';
import AskQuestionPopup from './AskQuestionPopup';
import { DialogContent } from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { setClosePopup } from '../../reducers/productInfoPopup';
import { useMemo } from 'react';

const DialogBox = ({ status, data }) => {
  const dispatch = useDispatch();

  const RenderElement = useMemo(() => {
    return ({ data }) => {
      const { component } = data;
      switch (component) {
        case "ProductShareCard":
          return <ProductShareCard data={data}/>;
        case "ShippingInfoCard":
          return <ShippingInfoCard data={data}/>;
        case "AskQuestionPopup":
          return <AskQuestionPopup details={data}/>;
        default:
          return null;
      }
    };
  }, []);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: "0px"
    }
  }));

  const handleClickClose = () => {
    dispatch(setClosePopup(false));
  }

  return (
    <BootstrapDialog
      open={status}
      keepMounted
      scroll={"paper"}
      onClose={() => handleClickClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent scroll={'paper'}>
        <RenderElement data={data} />
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DialogBox;
