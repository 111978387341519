import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { registerValidationSchema } from '../utils/yubSchema/authSchemeValidation';
import CustomizedSnackbars from '../components/baseComponents/Snackbar';
import { userRegisterApi } from '../utils/commonapi';
import { useDispatch } from 'react-redux';
import { errorToLogin, setLoginRequest, setUserDetails } from '../reducers/auth';

const Register = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();
  const { redirect } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    // Add padding to the body when component mounts
    document.body.classList.add('pb-0');

    // Clean up function to remove padding when component unmounts
    return () => {
      document.body.classList.remove('pb-0');
    };
  }, []); // Only run on mount and unmount

  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(
      registerValidationSchema
    ),
    mode: "onSubmit",
    reValidateMode: "onBlur"
  })
  const { errors } = formState;

  const handleApiError = (error) => {
    let errorMessage = 'An error occurred';
    if (error?.response) {
      errorMessage = error?.response?.data?.message;
    } else if (error?.request) {
      errorMessage = 'No response received from server';
    }
    setSnackbarMessage(errorMessage);
    setOpenSnackbar(true);
  };

  const handleSubmitRegister = async (data) => {
    try {
      dispatch(setLoginRequest());
      const response = await userRegisterApi(data);
      if (response) {
        const { data: { data, success, token } } = response;
        const objData = {
          userToken: token,
          userInfo: data,
          success: success
        }
        dispatch(setUserDetails(objData));
        const url = redirect ? redirect : '/';
        navigate(url);
      }
      reset();
    } catch (error) {
      const errorData = {
        error: error
      }
      dispatch(errorToLogin(errorData));
      console.log("🚀 ~ reviewHandleSubmit ~ error:", error);
      handleApiError(error);
    }
  }

  const handleCloseSnackbar = (isOpen) => {
    setOpenSnackbar(isOpen);
    setSnackbarMessage('');
  }

  return (
    <>
      <section className='auth-section'>
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 p-0 d-none d-lg-block">
              <div className="content-section">
                <div className="content">
                <h1>Eop</h1>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur inventore eaque illum fugiat repudiandae repellendus atque, nulla, autem sunt hic quasi fuga ex iste est! Id rerum autem ad dolorum.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="form-section">
                <div className="login-title">
                  <h2>Eop</h2>
                  <p>Sign Up Into Your Account</p>
                </div>
                <form action="" method="post" onSubmit={handleSubmit(handleSubmitRegister)}>
                  <div className="form-group mb-4">
                    <input type="text" name="name" {...register('name')} id="name" placeholder='Enter the Name' className='form-control name' />
                    <small className='text-danger err'>{errors?.name?.message}</small>
                  </div>
                  <div className="form-group mb-3">
                    <input type="text" name="email" {...register('email')} id="email" placeholder='Enter the email id' className='form-control email' />
                    <small className='text-danger err'>{errors?.email?.message}</small>
                  </div>
                  <div className="form-group mb-3">
                    <input type="text" name="phone" {...register('phone')} id="phone" placeholder='Enter the phone' className='form-control phone' />
                    <small className='text-danger err'>{errors?.phone?.message}</small>
                  </div>
                  <div className="form-group mb-3">
                    <input type="password" name="password" {...register('password')} id="password" placeholder='Enter the password' className='form-control password' />
                    <small className='text-danger err'>{errors?.password?.message}</small>
                  </div>
                  <div className="form-group mb-3">
                    <input type="password" name="c_password" {...register('c_password')} id="c_password" placeholder='Enter the confirm password' className='form-control c_password' />
                    <small className='text-danger err'>{errors?.c_password?.message}</small>
                  </div>

                  <button type="submit" className='btn btn-org'>Submit</button>
                  <p className='mt-3'>Already a member? <Link to={'/login'}>Login</Link></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {openSnackbar && <CustomizedSnackbars open={openSnackbar} message={snackbarMessage} severity="error" variant={'filled'} handleClose={handleCloseSnackbar} />}

    </>
  )
}

export default Register
