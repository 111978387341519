import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carts: [],
  cartIsOpen: false,
  loading: false,
  error: false,
  errorData: {}
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    cartRequest: (state, { payload }) => {
      state.loading = true;
    },
    setCartInfo: (state, { payload }) => {
      state.loading = false;
      state.carts = payload;
    },
    setAddCartInfo: (state, { payload }) => {
      state.carts = [...state.carts,payload]
    },
    setCartOpen: (state, { payload }) => {
      state.cartIsOpen = payload;
    },
    errorToCart: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
})
const { reducer, actions } = cartSlice;

export const {
  cartRequest,
  setCartInfo,
  setAddCartInfo,
  setCartOpen,
  errorToCart
} = actions;

export default reducer;
