import React from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { setClosePopup } from '../../reducers/productInfoPopup';
import { useDispatch } from 'react-redux';

const ShippingInfoCard = ({ data }) => {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    dispatch(setClosePopup(false));
  }

  return (
    <>
      <div className="shopping-info__card">
        <div className="card-header">
          <label className='title'>Shoopping Info</label>
          <div className="close_icon" onClick={handleClickClose}>
            <IoIosCloseCircleOutline />
          </div>
        </div>
        <div className="shopping-info__wrapper">
          <div className="shipping-term_wrapper">
            <p>
              Return Policy: <span>We will gladly accept returns for any reason within 30 days of receipt of delivery.</span>
            </p>
            <p>
              Availability: <span>Ships anywhere in the United States.</span>
            </p>
            <p>
              Processing Time: <span>Allow 3-4 business days processing time for your order to ship.</span>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShippingInfoCard;
