import React from 'react'
import { Badge } from '@mui/material'


const PayItemCard = ({ data }) => {

  return (
    <>
      {
        data?.products && Array.isArray(data?.products) && data?.products?.map((data,idx) => (
          <div className="payItem-card" key={idx}>
            <Badge badgeContent={data?.quantity} color="success">
              <div className="card-image">
                <img src={data?.image} alt="cardImage" />
              </div>
            </Badge>
            <div className="content">
              <p>{data?.name}</p>
              <div className="size-price-wrapper">
                <span>L</span>
                <span>Rs.{data?.price}</span>
              </div>
            </div>
          </div>
        ))
      }
    </>
  )
}

export default PayItemCard
