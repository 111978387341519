import * as Yup from 'yup';

export const shippingFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  news_updates: Yup.boolean(),
  shipping: Yup.object().shape({
    f_name: Yup.string().required('First name is required'),
    l_name: Yup.string().required('Last name is required'),
    country: Yup.string().required('Country is required'),
    address: Yup.string().required('Address is required'),
    apartments: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    pincode: Yup.string()
      .matches(/^\d{6}$/, { message: 'Pincode must be 6 digits', excludeEmptyString: true }) // Indian PIN code format
      .required('Postal code is required'),
  }),

  phone: Yup.string()
    .matches(/^[6-9]\d{9}$/, { message: 'Invalid phone number', excludeEmptyString: true }) // Indian phone number pattern
    .required('Phone number is required'),

  billingAddress: Yup.string().required('Billing address selection is required'), // Validation for radio button selection

  isSave: Yup.boolean(),

  billing: Yup.object().when('billingAddress', {
    is: (billingAddress) => {
      // Check if billingAddressValue meets a certain condition
      return billingAddress === 'different';
    },
    then: () => Yup.object().shape({
      billing_f_name: Yup.string().required('First name is required'),
      billing_l_name: Yup.string().required('Last name is required'),
      billing_country: Yup.string().required('Country is required'),
      billing_address: Yup.string().required('Street is required'),
      billing_apartments: Yup.string(),
      billing_city: Yup.string().required('City is required'),
      billing_state: Yup.string().required('State is required'),
      billing_pincode: Yup.string()
        .matches(/^\d{6}$/, { message: 'Pincode must be 6 digits', excludeEmptyString: true }) // Indian PIN code format
        .required('Postal code is required'),
    }),
    otherwise: () => Yup.object().notRequired(), // No billing address validation if it's same as shipping
  }),

});
