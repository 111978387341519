import { appAxios } from './axios-base-utils';

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },

    children: `${name.split(' ')[0][0]}${name.split(' ')[0][1]}`,
  };
}

// Function to calculate the discount amount
export const calculateDiscount = (originalPrice, salePrice) => {
  // Calculate the discount amount
  const discountAmount = originalPrice - salePrice;

  // Calculate the discount percentage
  const discountPercentage = (discountAmount / originalPrice) * 100;

  // Round the discount percentage to two decimal places
  const roundedDiscountPercentage = Math.round(discountPercentage * 100) / 100;

  return roundedDiscountPercentage;
};

export const discountPercentageCalculate = (price, discountPercentage) => {
  if (!discountPercentage || discountPercentage === 0) {
        return price; // Return current price if no discount applied
    }
    const finalPrice = price - (price * discountPercentage) / 100
    return Math.round(finalPrice); // Calculate discounted price
}

// Function to calculate total amount using reduce method
export const calculateTotal = (arr) => {
  return arr.reduce((total, item) => {
    return total + (item.price * item.quantity);
  }, 0);
};

export const getAllProducts = () => appAxios.get('/api/allproducts');

export const getTrendingProducts = () => appAxios.get('/api/v1/trending');

export const getProductById = (id) => appAxios.get(`/api/v1/product/${id}`);

export const testimonial = () => appAxios.get('/api/testimonial');

export const getLatestCourses = () => appAxios.get('/api/latest');

export const getAllCarts = () => appAxios.get('/api/cart');

