import React, { useState } from 'react';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import { GrFacebookOption } from "react-icons/gr";
import { MdWhatsapp } from "react-icons/md";
import { RiTwitterXFill } from "react-icons/ri";
import { IoIosMore } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { setClosePopup } from '../../reducers/productInfoPopup';
import { useDispatch } from 'react-redux';

const ProductShareCard = ({ data }) => {
  const dispatch = useDispatch();

  const { data: { url } } = data;

  const [inputValue, setInputValue] = useState(url);
  const isMoreButton = navigator.share;
  const handleShare = async () => {
    try {
      if (navigator.share) {
        console.log('check');
        await navigator.share(url);
      } else {
        throw new Error('Web Share API not supported');
      }
    } catch (error) {
      console.error('Error sharing:', error.message);
      // Handle error gracefully
    }
  };

  const handleClickClose = () => {
    dispatch(setClosePopup(false));
  }

  return (
    <>
      <div className="product-share__popup">
        <div className="card-header">
          <label className='title'>Share</label>
          <div className="close_icon" onClick={handleClickClose}>
            <IoIosCloseCircleOutline />
          </div>
        </div>
        <div className="product-share_inlink">
          <div className="product-copylink__wrapper">
            <input type="text" className='product-copylink__input' name='copylink' id='product-copylink__input' onChange={(e)=>setInputValue(e.target.value)} value={inputValue || ""}/>
            <button className='btn btn-copy'>Copy</button>
          </div>
        </div>
        <div className="product-share_socialmedia">
          <label className='title'>Share On Social Media</label>
          <div className="social-media__wrapper">
            <FacebookShareButton url={url}>
              <div className="icons">
                <GrFacebookOption />
              </div>
            </FacebookShareButton>
            <WhatsappShareButton url={url}>
              <div className="icons">
                <MdWhatsapp />
              </div>
            </WhatsappShareButton>
            <TwitterShareButton url={url}>
              <div className="icons">
                <RiTwitterXFill />
              </div>
            </TwitterShareButton>
            {
              isMoreButton && <div className="icons" onClick={handleShare}>
                <IoIosMore/>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductShareCard;
