import React, { useEffect, useRef } from 'react';
import { Route, Routes } from "react-router-dom";
import { CartPage, Home, ProductDetails } from './route';
import Login from './pages/Login';
import Register from './pages/Register';
import { getCarts, userMe } from './utils/commonapi';
import { useDispatch } from 'react-redux';
import { errorToLogin, setLoginRequest, setUserDetails } from './reducers/auth';
import Cookies from 'js-cookie';
import NotFound from './components/baseComponents/NotFound';
import { cartRequest, errorToCart, setCartInfo } from './reducers/cartSlice';
import Success from './components/baseComponents/Success';
import ShippingDetails from './pages/ShippingDetails';

function App() {
  const dispatch = useDispatch();
  const token = Cookies.get('token');
  const onetimeRef = useRef(true)
  useEffect(() => {
   // Get the token from the cookie
    if (token && onetimeRef.current) {
      const getUserMe = async () => {
        try {
          dispatch(setLoginRequest());
          const response = await userMe();
          if (response) {
            const { data: { data, success, token } } = response;
            const objData = {
              userToken: token,
              userInfo: data,
              success: success
            }
            dispatch(setUserDetails(objData));
          }
        } catch (error) {
          const errorData = {
            error: error
          }
          dispatch(errorToLogin(errorData));
          console.log("🚀 ~ error:", error)
        }
      }
      getUserMe();
      const getMyCarts = async () => {
        try {
          dispatch(cartRequest());
          const { data: { data } } = await getCarts();
          dispatch(setCartInfo(data));
        } catch (error) {
          dispatch(errorToCart(error));
          console.log("🚀 ~ getMyCarts ~ error:", error)
        }
      }
      getMyCarts();
      onetimeRef.current = false;
    }
  }, [dispatch,token])


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/product/:id" element={<ProductDetails />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/success" element={<Success />} />
      <Route path="/checkouts/:productId" element={<ShippingDetails />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
}

export default App;
