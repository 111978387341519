import React from 'react';
import productImage from '../../images/home/product-18.webp';
import starImage from '../../images/icons/star.svg';
import cartImage from '../../images/icons/cart.svg';
import SectionTitle from '../baseComponents/section-title';

const DealsOfTheDay = () => {
  return (
    <>
      <section className='deals-of-the-day__Section section_padding'>
        <div className="container-fluid">
          <SectionTitle title={"Deals Of The Day"} />
          <div className="deals-of-the-day__container mt-3 mt-md-4">
            <div className="deals_card">
              <div className="card-image">
                <img src={productImage} alt="product" />
              </div>
              <div className="card-content">
                <h1>Mens Casual Premium Slim Fit T-Shirts</h1>
                <div className="rating">
                  <span>4.4</span>
                  <img src={starImage} alt="starImage" />
                </div>
                <p className="price">Rs.255</p>
                <button className='btn add-to-cart'>
                  <img src={cartImage} alt="cartImage" />
                  <span>Add To Cart</span>
                </button>
              </div>
            </div>
            <div className="deals_card">
              <div className="card-image">
                <img src={productImage} alt="product" />
              </div>
              <div className="card-content">
                <h1>Mens Casual Premium Slim Fit T-Shirts</h1>
                <div className="rating">
                  <span>4.4</span>
                  <img src={starImage} alt="starImage" />
                </div>
                <p className="price">Rs.255</p>
                <button className='btn add-to-cart'>
                  <img src={cartImage} alt="cartImage" />
                  <span>Add To Cart</span>
                </button>
              </div>
            </div>
            <div className="deals_card">
              <div className="card-image">
                <img src={productImage} alt="product" />
              </div>
              <div className="card-content">
                <h1>Mens Casual Premium Slim Fit T-Shirts</h1>
                <div className="rating">
                  <span>4.4</span>
                  <img src={starImage} alt="starImage" />
                </div>
                <p className="price">Rs.255</p>
                <button className='btn add-to-cart'>
                  <img src={cartImage} alt="cartImage" />
                  <span>Add To Cart</span>
                </button>
              </div>
            </div>
            <div className="deals_card">
              <div className="card-image">
                <img src={productImage} alt="product" />
              </div>
              <div className="card-content">
                <h1>Mens Casual Premium Slim Fit T-Shirts</h1>
                <div className="rating">
                  <span>4.4</span>
                  <img src={starImage} alt="starImage" />
                </div>
                <p className="price">Rs.255</p>
                <button className='btn add-to-cart'>
                  <img src={cartImage} alt="cartImage" />
                  <span>Add To Cart</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DealsOfTheDay;
