import React, { useEffect, useState } from 'react';
import SectionTitle from '../baseComponents/section-title';
import SwiperComponent from '../baseComponents/swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import Card1 from '../baseComponents/Card1';
import { appAxios } from '../../utils/axios-base-utils';
const DailyBestSells = () => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await appAxios.get('https://fakestoreapi.com/products?limit=5');
        setData(response?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);


  const swiperParams = {
    slidesPerView: 1.2,
    spaceBetween:16,
    autoplay:{
      delay: 3000,
      // disableOnInteraction: false,
    },
    speed:500,
    modules:[Autoplay, EffectFade],
    className:"mySwiper",
    loop: true,
    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      640: {
        slidesPerView: 1.75,
      },
      768: {
        slidesPerView: 1.9,
      },
      1024: {
        slidesPerView: 2.5,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 32
      }
    }
  }
  return (
    <>
      <section className='daily-best-sells__Section section_padding'>
        <div className="container-fluid">
          <SectionTitle title={"Daily Best Sells"} />
          <div className="daily-best-sells__container mt-3 mt-md-4">
            <SwiperComponent
              cardData={data}
              cardComponent={<Card1 />}
              swiperParams={swiperParams}>
            </SwiperComponent>
          </div>
        </div>
      </section>
    </>
  )
}

export default DailyBestSells;
