import React, { useEffect, useState } from 'react';
import SectionTitle from '../baseComponents/section-title';
// import productImage from '../../images/home/product.png';
import starImage from '../../images/icons/star.svg';
import { useNavigate } from 'react-router-dom';
import { calculateDiscount, getTrendingProducts } from '../../utils/common';
const MostPopularProduct = () => {
  const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: { data } } = await getTrendingProducts();
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClick = (id) => {
    navigate(`product/${id}`)
  }
  return (
    <>
      <section className="most-popular-product__section section_padding">
        <div className="container-fluid">
          <SectionTitle title={"Trending Products"} />
          <div className="mostPopular___container mt-3 mt-md-4">
            {
              data && Array.isArray(data) && data?.map((item, idx) => (
                <div className="mostPopular__card" key={idx} onClick={() => handleClick(item?._id)}>
                  <div className="card-image">
                    <img src={item?.images[0]?.image} className='img-fluid' alt="product" />
                  </div>
                  <div className="card-body">
                    <h6>{ item?.name }</h6>
                    <div className="solid_and_rating">
                      <div className="rating">
                        <span>{ parseFloat(item?.ratings?.toFixed(1)) }</span>
                        <img src={starImage} className='img-fluid' alt="star" />
                      </div>
                      <div className="line">
                      </div>
                      <p>( { item?.reviewsCount } Reviews )</p>
                    </div>
                    <div className="price_wrapper">
                      <p className="price">Rs.{item?.salePrice ? item?.salePrice : 0}</p>
                      {
                        item?.salePrice !== item?.price &&  <p className="discount_price">Rs.{item?.price}</p>
                      }
                      {
                        item?.salePrice !== item?.price  &&  <p>{calculateDiscount(item?.price, item?.salePrice)}%</p>
                      }
                    </div>
                    <p className="free_delivery">
                      Free delivery
                    </p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default MostPopularProduct;
