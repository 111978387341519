import React from 'react'
import Header from '../components/groupComponents/header'
import ShippingForm from '../components/groupComponents/ShippingForm'

const ShippingDetails = () => {
  return (
    <>
      <Header />
      <ShippingForm />
    </>
  )
}

export default ShippingDetails
