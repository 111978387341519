import React, { useEffect, useState } from 'react'
import ImageComponent from '../baseComponents/ImageComponent';
// import productImage from '../../images/home/product-info.webp'
import StarRating from '../baseComponents/StarRating';
import tickMark from '../../images/icons/check-circle.svg';
import closeIcon from '../../images/icons/red-close-icon.svg';
import soldOut from '../../images/icons/sold.svg';
import eyeIcon from '../../images/icons/eye.svg';
import heartIcon from '../../images/icons/heart.svg';
import questionIcon from '../../images/icons/question.svg';
import truckIcon from '../../images/icons/truck.svg';
import shareIcon from '../../images/icons/share.svg';
import {
  useNavigate,
  // useNavigate,
  useParams
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { setAddCartInfo } from '../../reducers/cartSlice';
import { getProductById } from '../../utils/common';
import { setPopupOpen, setPopupData } from '../../reducers/productInfoPopup';
import Card5 from '../baseComponents/Card5';
import AccordionUsage from '../baseComponents/Accordion';
import AddReview from '../baseComponents/AddReview';
import { addCart, getCarts, getReviewById } from '../../utils/commonapi';
import CustomTooltip from '../baseComponents/BootstrapTooltip';
import { cartRequest, setCartInfo } from '../../reducers/cartSlice';

const ProductInfo = () => {
  const { cart: { carts } } = useSelector(state => state);
  const [data, setData] = useState([]);
  const [productIsAdded, setProductIsAdded] = useState(false);
  const [activeQuatity, setActiveQuatity] = useState(250);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [reviews, setReviews] = useState();

  const { id } = useParams();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selector = useSelector(state => state);
  const userToken = selector && selector?.auth && selector?.auth?.userToken;



  useEffect(() => {
    const checkId = carts && carts?.items && carts?.items?.find(obj =>   obj?.productId?._id === String(id));
    checkId ? setProductIsAdded(true) : setProductIsAdded(false);
  },[carts,id])

  useEffect(() => {
    const productData = async () => {
      try {
        const { data: { data } } = await getProductById(id);
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        navigate('/404');
      }
    }
    productData();
    const getReviews = async () => {
      try {
        const { data: { data } } = await getReviewById(id);
        setReviews(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    getReviews();
  }, [id, isReview, navigate])



  const handleClickPopupOpen = (status, clickElement) => {
    dispatch(setPopupOpen(status));
    dispatch(setPopupData(popupInfo[clickElement]));
  }

  const handleActiveQuatity = (quatity) => {
    setActiveQuatity(quatity)
  }

  const popupInfo = {
    share: {
      component: "ProductShareCard",
      data: {
        url : window.location.href
      }
    },
    ShippingInfoCard: {
      component: "ShippingInfoCard",
      data: {
        url : window.location.href
      }
    },
    AskQuestionPopup: {
      component: "AskQuestionPopup",
      data: data
    }
  }

  const handleAddReview = (status) => {
    setReviewPopup(status);
  }

  const handleAddToCart = async (productId) => {
    if (!productIsAdded) {
      const newdata = {
        productId: productId,
        quantity: 1
      }

      try {
        await addCart(newdata);
        setProductIsAdded(true);
        dispatch(cartRequest());
        const { data: { data } } = await getCarts();
        dispatch(setCartInfo(data));
      } catch (error) {
        console.log("🚀 ~ handleAddToCart ~ error:", error)
      }
    } else {
      navigate("/cart")
    }
  }


  // const handleBuyProduct = async () =>{
  //   try {
  //     const productItem = {
  //       products: [
  //         {
  //           name: data?.name,
  //           image: data?.images?.[0]?.image,
  //           price: data?.salePrice,
  //           quantity: 1
  //         }
  //       ]
  //     }
  //     const { data: { sessionId } } = await buyProduct(productItem);
  //     // Initialize Stripe with your publishable key
  //     const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  //     // Redirect to Checkout
  //     const { error } = await stripe.redirectToCheckout({
  //       sessionId,
  //     });

  //     if (error) {
  //       console.error('Error redirecting to checkout:', error);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ handleBuyProduct ~ error:", error)
  //   }
  // }

  const handleCheckouts = () => {
    const productItem = {
      products: [
        {
          id:id,
          name: data?.name,
          image: data?.images?.[0]?.image,
          price: data?.salePrice,
          quantity: 1
        }
      ]
    };
    sessionStorage.setItem("productItem", JSON.stringify(productItem));
    navigate(`/checkouts/${id}`)
  }

  return (
    <>
      <section className='product-info__section'>
        <div className="container-fluid">
          <div className="product-info__wrapper">
            <div className="product-image">
              <ImageComponent url={data?.images?.[0]?.image} altText={"productImage"}/>
            </div>
            <div className="product-content">
              <div className="common-wrapper">
                <h6>Cocount Oil</h6>
                <h1>{data?.name}</h1>
                {
                  data?.description?.length > 140 ? <p className='desc'>
                    {data?.description?.substring(0,140)}
                  </p> : <p className='desc'>
                    {data?.description}
                  </p>
                }
                <div className="price-wrapper">
                  {
                    data?.salePrice !== data?.price && <p className='original-price'>Rs.{data?.price}</p>
                  }
                  <p>Rs.{data?.salePrice}</p>
                </div>
                <div className="rating-wrapper">
                  <StarRating rating={data?.ratings} mode={"readOnly"} />
                  <p>{data?.reviewsCount} Reviews</p>
                </div>
                <p className='by-author'>
                  By <span>JK</span>
                </p>
              </div>
              <div className="line"></div>
              <div className="common-wrapper">
                <ul className='product-current__details'>
                  {
                    data?.quantity ?
                    <li>
                      <img src={tickMark} alt="tick" />
                      <span className='text-green'>In stock</span>
                    </li> :
                    <li>
                      <img src={closeIcon} alt="tick" />
                      <span className='text-red'>Out of stock</span>
                    </li>
                  }
                  <li>
                    <img src={soldOut} alt="tick" />
                    <span className='text-red'>33 sold in last 24 hours</span>
                  </li>
                  <li>
                    <img src={eyeIcon} alt="tick" />
                    <span className='text-litegreen'>40 people are viewing right now </span>
                  </li>
                </ul>
              </div>
              <div className="line"></div>
              <div className="common-wrapper">
                <div className="quatity-wrapper">
                  <button className={`btn btn-quatity ${activeQuatity === 250 ? 'active' : ''}`} onClick={()=>handleActiveQuatity(250)}>250 ML</button>
                  <button className={`btn btn-quatity ${activeQuatity === 500 ? 'active' : ''}`} onClick={()=>handleActiveQuatity(500)}>500 ML</button>
                  <button className={`btn btn-quatity ${activeQuatity === 1000 ? 'active' : ''}`} onClick={()=>handleActiveQuatity(1000)}>1 LT</button>
                </div>
                <div className="add-to-cart-wrapper">
                  <button className="btn btn-add-cart"
                    onClick={() => handleAddToCart(id)}
                  >
                    {productIsAdded ? "Go To Cart" : "Add To Cart"}
                  </button>
                  <button className="btn btn-watchlist">
                    <img src={heartIcon} alt="heartIcon" />
                  </button>
                </div>
                {
                  userToken ?
                  <button className="btn btn-buynow" onClick={handleCheckouts} disabled={!data?.quantity}>
                    Buy Now
                  </button>
                  :
                  <button className="btn btn-buynow" onClick={() => {
                    navigate(`/login?redirect=${window.location.href}`)
                  }}>
                    Log In
                  </button>
                }

                <div className="text-wrapper">
                  <div className="item" onClick={() => handleClickPopupOpen(true, "AskQuestionPopup")}>
                    <img src={questionIcon} alt="questionIcon"/>
                    <span>Ask a question</span>
                  </div>
                  <div className="item" onClick={()=>handleClickPopupOpen(true,"ShippingInfoCard")}>
                    <img src={truckIcon} alt="questionIcon" />
                    <span>Delivery & Return</span>
                  </div>
                  <div className="item" onClick={()=>handleClickPopupOpen(true,"share")}>
                    <img src={shareIcon} alt="questionIcon" />
                    <span>Share</span>
                  </div>
                </div>
              </div>
              <div className="line"></div>
              <div className="deliveryinfo">
                <img src={truckIcon} alt="questionIcon"/>
                <span>Order in the next 7 hours 12 minutes to get it between Monday, Dec 4 and Friday, Dec 8</span>
              </div>
              <AccordionUsage id={`${id}-description`} title={'Description'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci inventore aperiam quo natus itaque? In sed dolores est ut fugiat molestiae, debitis veritatis sequi voluptates maiores, sint eaque quibusdam error.'} />
              <AccordionUsage id={`${id}-shipping`} title={'Shipping & Return'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci inventore aperiam quo natus itaque? In sed dolores est ut fugiat molestiae, debitis veritatis sequi voluptates maiores, sint eaque quibusdam error.'} />
            </div>
          </div>
          <div className="other-section__wrapper">
            <div className="custumer-review__section">
              <div className="custumer-review__header">
                <div className="title-wrapper">
                  <h2>Customer Reviews</h2>
                  {
                    userToken ? <button className='btn btn-green btn-sm' onClick={() => handleAddReview(true)}>Write a Review</button> :
                    <CustomTooltip title={'Please log in first.'} type={'bootstrap'}>
                      <button className='btn btn-green btn-sm'>Write a Review</button>
                    </CustomTooltip>
                  }
                </div>
                <StarRating rating={data?.ratings === 0 ? 4.4 : data?.ratings} mode={"readOnly"} />
              </div>
              <div className="custumer-review__body">
                {
                  reviews && Array.isArray(reviews) && reviews?.map((item,idx) => (
                    <Card5 data={item} key={idx}/>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {reviewPopup && <AddReview status={reviewPopup} handleClickClose={handleAddReview} reviewStatus={isReview} reviewStatusSet={setIsReview} />}
    </>
  )
}
export default ProductInfo;
