import React, { useEffect, useState } from 'react';
import Card4 from '../baseComponents/Card4';
import { useDispatch, useSelector } from 'react-redux';
import noCartImage from '../../images/empty-cart.svg';
import { getCarts, removeCart, updateCart } from '../../utils/commonapi';
import { cartRequest, setCartInfo } from '../../reducers/cartSlice';

const CartItems = () => {
  const dispatch = useDispatch();

  const [cartItems, setCartItems] = useState();
  const selector = useSelector(state => state);
  const data = selector && selector?.cart?.carts;

  useEffect(() => {
    setCartItems(data);
  }, [data]);

  const handleQuantityFunc = async (event, method, productId) => {
    event.stopPropagation();
    const matchedItem = cartItems && cartItems.items && Array.isArray(cartItems.items) && cartItems?.items.find(item => item?.productId?._id === productId);
    const quantity = matchedItem?.quantity;
    const availableQuantity = matchedItem?.productId?.quantity;
    let updatedQuantity = method === "dec" ? quantity - 1 : quantity + 1;

    if (updatedQuantity === 0) {
      updatedQuantity = 1;
    } else if(updatedQuantity >= availableQuantity){
      updatedQuantity = availableQuantity;
    }

    const updateCartData = {
      productId: productId,
      quantity: updatedQuantity
    }

    try {
      await updateCart(updateCartData);
      dispatch(cartRequest());
      const { data: { data } } = await getCarts();
      dispatch(setCartInfo(data));
    } catch (error) {
      console.log("🚀 ~ handleAddToCart ~ error:", error)
    }
  }

  const hanldeRemoveCart = async (event, productId) => {
    event.stopPropagation();
    try {
      const removeData = {
        productId
      }
      await removeCart(removeData);
      dispatch(cartRequest());
      const { data: { data } } = await getCarts();
      dispatch(setCartInfo(data));
    } catch (error) {
      console.log("🚀 ~ hanldeRemoveCart ~ error:", error)
    }
  }

  return (
    <>
      <section className='cart-items__wrapper'>
        <div className="container-fluid">
          {
            data?.items?.length > 0 &&
            <div className="cart-items__container">
              <div className="cart-item__details">
                {
                  data && data?.items && Array.isArray(data?.items) && data?.items?.map((item, idx) => (
                    <Card4 className={"full-viewCard"} data={item} key={idx} handleQuantityClick={handleQuantityFunc} removeClickFunc={hanldeRemoveCart}/>
                  ))
                }
              </div>
              <div className="payment__details">
                <div className="payment-card">
                  <div className="payment-details__header">
                    <h6>Payment Details</h6>
                  </div>
                  <div className="payment-details__body">
                    <div className="price-details-wrapper">
                      <div className="price-details">
                        <p>Price &#40; 2 Items &#41;</p>
                        <div className="price">
                          <span className='text-dark'>Rs.3000</span>
                        </div>
                      </div>
                      <div className="price-details">
                        <p>Discount</p>
                        <div className="price">
                          <span className='text-green'>- Rs.1000</span>
                        </div>
                      </div>
                      <div className="price-details">
                        <p>Delivery Charges</p>
                        <div className="price">
                          <span className='text-green'>Free</span>
                        </div>
                      </div>
                    </div>
                    <div className="total-price__wrapper">
                      <div className="price-details">
                        <p className='cfw-600'>Total Price</p>
                        <div className="price">
                          <span className='text-green cfw-600'>Rs.2000</span>
                        </div>
                      </div>
                    </div>
                    <div className="mge-wrapper">
                      <p>You will save ₹1000 on this order</p>
                    </div>
                    <button className='btn btn-green w-100 mb-3'>Place Order</button>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            !data && <div className="nocart-wrapper page-nocart__wrapper">
              <img src={noCartImage} className='img-fluid' alt="noCartImage" />
              <p>Your shopping cart is empty!</p>
            </div>
          }
        </div>
      </section>
    </>
  )
}

export default CartItems;
